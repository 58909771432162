<template>
  <div>
    <LoadingSpinner :isLoading="isLoading" />
    <modal name="update-alarm-contract" class="modal-inner modal-update-alarm-contract" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
      <span class="close-button" @click="hide('update-alarm-contract')"><BaseIcon icon="times-circle" class="text-white"/></span>
      <div class="modal-header text-lg bg-primary-50 font-serif">
        <span v-if="updateInfo"> {{ $t("change_alarm_plan.change_alarm_plan") }} - {{ updateInfo.unit_name }} </span>
      </div>
      <div class="modal-body">
        <ValidationObserver ref="formUpdateAlarmContract" v-if="updateInfo != null">
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2">
              <BaseSelect v-model="updateInfo.type" :options="devices" :field_name="$t('change_alarm_plan.device_type')" rules="required" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(TWIG Beacon)').test(updateInfo.type)">
              <BaseInput v-model.trim="updateInfo.imei_number" type="text" :field_name="$t('change_alarm_plan.imei')" rules="required" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(TWIG Beacon)').test(updateInfo.type)">
              <BaseInput v-model.trim="updateInfo.phone" type="text" :field_name="$t('change_alarm_plan.telematics_number')" rules="required" :readonly="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller'" :disabled="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller'" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model.trim="updateInfo.modelnumber" type="text" :field_name="$t('change_alarm_plan.serial_number')" rules="required" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)').test(updateInfo.type)">
              <BaseSelect v-model="updateInfo.operator" :options="sim_operators" :field_name="$t('change_alarm_plan.sim_operator')" rules="required" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5"></div>

          <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
            <div class="w-full md:w-1/3 px-2" v-if="user.role != 'superuser' && user.role != 'user'">
              <BaseSelect v-model="updateInfo.alarm_central" :options="[{ name: 'Ingen', _id: '-' }, ...alarmCentrals]" nameSelector="name" optionSelector="_id" :field_name="$t('change_alarm_plan.alarm_center')" rules="required" @change="check_alarm_number()" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-else>
              <BaseInput value="OK" type="text" :field_name="$t('change_alarm_plan.alarm_center')" :readonly="true" :disabled="true" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="show_alarm_number">
              <BaseInput v-model.trim="updateInfo.alarm_number" type="text" rules="required" :field_name="$t('change_alarm_plan.alarm_number')" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseSwitch v-model="updateInfo.man_down" :field_name="$t('change_alarm_plan.man_down')" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)"></div>

          <div class="flex flex-wrap mb-5" v-if="user.role == 'admin'">
            <div class="w-full md:w-1/3 px-2">
              <label class="inline-flex items-center mt-3">
                <input v-model="updateInfo.cash_agreement" :value="updateInfo.cash_agreement" type="checkbox" class="form-checkbox h-4 w-4" @change="switchAgreementType()" />
                <span class="ml-2 text-sm font-sans font-semibold">{{ $t("change_alarm_plan.cash_agreement") }}</span>
              </label>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2" v-if="updateInfo.cash_agreement">
              <BaseInput v-model="updateInfo.agreement_number" type="text" :field_name="$t('change_alarm_plan.agreement_number')" readonly disabled />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="updateInfo.cash_agreement">
              <BaseInput v-model="updateInfo.agreement_length" type="text" :field_name="$t('change_alarm_plan.contract_length')" readonly disabled />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!updateInfo.cash_agreement">
              <label class="text-gray-700 text-sm font-bold pb-2">{{ $t("change_alarm_plan.agreement_number") }}<b class="required-star">*</b></label>
              <ValidationProvider :name="$t('change_alarm_plan.agreement_number')" rules="required" v-slot="{ classes, errors }">
                <div class="input-validate" :class="classes">
                  <input-mask class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 disabled:opacity-50" type="text" v-model="updateInfo.agreement_number" mask="SE9999-9999" :readonly="user.role != 'admin'" :disabled="user.role != 'admin'"></input-mask>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!updateInfo.cash_agreement">
              <BaseInput v-model.trim="updateInfo.agreement_length" type="text" :field_name="$t('change_alarm_plan.contract_length')" rules="required" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <ValidationProvider name="Startdatum" rules="required" v-slot="{ classes, errors }">
                <div class="input-validate" :class="classes">
                  <label class="text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("change_alarm_plan.start_date") }}<b class="text-red-500 ml-1">*</b></label>
                  <date-picker class="mt-2" :lang="date_picker_lang" :key="date_picker_langKey" v-model="updateInfo.contract_start" value-type="format" format="YYYY-MM-DD" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'"></date-picker>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseSelect v-model="updateInfo.seller" :options="sellers" nameSelector="name" optionSelector="_id" :field_name="$t('change_alarm_plan.seller')" :readonly="user.role != 'admin' && user.role != 'seller'" :disabled="user.role != 'admin' && user.role != 'seller'" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5"></div>

          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.delivery_company.name" type="text" :field_name="$t('change_alarm_plan.business')" readonly disabled />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.delivery_company.phone" type="text" :field_name="$t('change_alarm_plan.phone')" readonly disabled />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.delivery_company.email" type="text" :field_name="$t('change_alarm_plan.email')" readonly disabled />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5"></div>

          <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
            <div class="w-full md:w-1/3 px-2">
              <BaseSelect v-model="updateInfo.selected_alarm_sort" :options="[$t('change_alarm_plan.group_alarm'), $t('change_alarm_plan.personal_alarm')]" :field_name="$t('change_alarm_plan.alarm_type')" rules="required" @change="check_personal_alarm()" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseSelect v-model="updateInfo.risk_profile" :options="[$t('risk_profile.threat_alarm'), $t('risk_profile.emergency_alarm'), $t('risk_profile.burglar_alarm')]" rules="required" :field_name="$t('change_alarm_plan.risk_profile')" :readonly="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller'" :disabled="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller'" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.user" type="number" rules="required" :field_name="$t('change_alarm_plan.number_of_users')" />
            </div>
          </div>

          <div class="flex flex-wrap" v-if="show_personal_alarm && !new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.personal_alarm.reporting" type="text" :field_name="$t('change_alarm_plan.signal_element')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.personal_alarm.risk_profile" type="text" :field_name="$t('change_alarm_plan.risk_profile')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.personal_alarm.name" type="text" :field_name="$t('change_alarm_plan.name')" rules="required" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)"></div>

          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.unit_name" type="text" rules="required" :field_name="$t('change_alarm_plan.product_name')" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
              <BaseInput v-model="updateInfo.from_address.city" type="text" rules="required" :field_name="$t('change_alarm_plan.based_on_address')" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
              <BaseInput v-model="updateInfo.alarm_unit_name" type="text" rules="required" :field_name="$t('change_alarm_plan.business_name')" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
              <BaseTextarea rows="5" v-model="updateInfo.business_description" :field_name="$t('change_alarm_plan.work_assignment')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="updateInfo.type == 'SRT330' || updateInfo.type == 'SRT430'">
              <BaseTextarea rows="5" v-model="updateInfo.comment" :field_name="$t('change_alarm_plan.comment')" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)"></div>

          <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
            <div class="w-full text-xs italic px-2 mb-2">
              {{ $t("change_alarm_plan.device_password") }}
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
              <BaseInput v-model="updateInfo.alarm_password" type="text" rules="required" :field_name="$t('change_alarm_plan.password')" />
            </div>
            <div class="w-full md:w-1/3 px-2" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
              <BaseInput v-model="updateInfo.personal_password" type="text" :field_name="$t('change_alarm_plan.personal_password')" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)').test(updateInfo.type)"></div>

          <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)').test(updateInfo.type)">
            <div class="w-full text-xs italic px-2 mb-2">
              {{ $t("change_alarm_plan.external_agent") }}
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.external_contractor.name" type="text" :field_name="$t('change_alarm_plan.name')" :readonly="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller' && user.role != 'superuser' && user.role != 'user'" :disabled="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller' && user.role != 'superuser' && user.role != 'user'" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.external_contractor.phone" type="text" :field_name="$t('change_alarm_plan.contact_number')" :readonly="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller' && user.role != 'superuser' && user.role != 'user'" :disabled="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller' && user.role != 'superuser' && user.role != 'user'" />
            </div>
            <div class="w-full md:w-1/3 px-2">
              <BaseInput v-model="updateInfo.external_contractor.object_id" type="text" :field_name="$t('change_alarm_plan.id_perpetrator')" :readonly="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller' && user.role != 'superuser' && user.role != 'user'" :disabled="user.role != 'admin' && user.role != 'seller' && user.role != 'reseller' && user.role != 'superuser' && user.role != 'user'" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)"></div>

          <div class="flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
            <div class="w-full text-sm font-bold px-2 mb-2">
              {{ $t("change_alarm_plan.contact_person") }}
            </div>
            <div class="w-full text-xs italic px-2 mb-2">
              <p>{{ $t("change_alarm_plan.contact_person_details") }}</p>

              <ol>
                <li>{{ $t("change_alarm_plan.wardenship") }} – 031 703 10 00 - {{ $t("change_alarm_plan.everyday") }} 08-16</li>
                <li>{{ $t("change_alarm_plan.palm_leather") }} – 0799 99 99 99 - {{ $t("change_alarm_plan.around_the_clock") }}</li>
              </ol>
            </div>
            <div class="w-full flex flex-wrap" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
              <div class="w-full md:w-3/12 px-2">
                <BaseInput v-model="updateInfo.contact_persons[0].name" type="text" rules="required" :field_name="$t('change_alarm_plan.name')" :placeholder="$t('change_alarm_plan.name')" />
              </div>
              <div class="w-full md:w-3/12 px-2">
                <BaseInput v-model="updateInfo.contact_persons[0].function" type="text" rules="required" :field_name="$t('change_alarm_plan.function')" :placeholder="$t('change_alarm_plan.function')" />
              </div>
              <div class="w-full md:w-3/12 px-2">
                <BaseInput v-model="updateInfo.contact_persons[0].phone" type="text" rules="required" :field_name="$t('change_alarm_plan.number')" :placeholder="$t('change_alarm_plan.number')" />
              </div>
              <div class="w-full md:w-1/12 px-2">
                <BaseInput v-model="updateInfo.contact_persons[0].weekday" type="text" :field_name="$t('change_alarm_plan.everyday')" :placeholder="$t('change_alarm_plan.everyday')" />
              </div>
              <div class="w-full md:w-1/12 px-2">
                <BaseInput v-model="updateInfo.contact_persons[0].saturday" type="text" :field_name="$t('change_alarm_plan.saturday')" placeholder="$t('change_alarm_plan.saturday')" />
              </div>
              <div class="w-full md:w-1/12 px-2">
                <BaseInput v-model="updateInfo.contact_persons[0].sunday" type="text" :field_name="$t('change_alarm_plan.sun_weekend')" placeholder="$t('change_alarm_plan.sun_weekend')" />
              </div>
            </div>
            <div class="w-full" v-for="(c, i) in updateInfo.contact_persons" :key="i">
              <div class="flex flex-wrap" v-if="i > 0">
                <div class="w-full md:w-3/12 px-2">
                  <BaseInput v-model="c.name" type="text" :placeholder="$t('change_alarm_plan.name')" />
                </div>
                <div class="w-full md:w-3/12 px-2">
                  <BaseInput v-model="c.function" type="text" :placeholder="$t('change_alarm_plan.function')" />
                </div>
                <div class="w-full md:w-3/12 px-2">
                  <BaseInput v-model="c.phone" type="text" :placeholder="$t('change_alarm_plan.number')" />
                </div>
                <div class="w-full md:w-1/12 px-2">
                  <BaseInput v-model="c.weekday" type="text" :placeholder="$t('change_alarm_plan.everyday')" />
                </div>
                <div class="w-full md:w-1/12 px-2">
                  <BaseInput v-model="c.saturday" type="text" :placeholder="$t('change_alarm_plan.saturday')" />
                </div>
                <div class="w-full md:w-1/12 px-2">
                  <BaseInput v-model="c.sunday" type="text" :placeholder="$t('change_alarm_plan.sun_weekend')" />
                </div>
              </div>
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5" v-if="(user.role == 'admin' || user.role == 'seller') && !new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)"></div>

          <div class="flex flex-wrap mt-4" v-if="!new RegExp('(SRT334)|(SRT330)|(SRT430)|(TWIG Beacon)|(^TELTONIKA)|(4G Box)').test(updateInfo.type)">
            <div class="w-full px-2 text-xs italic" v-if="updateInfo.risk_profile == 'Hotlarm'">
              <h5 class="font-bold mb-1">{{ $t("primary_input.primary_input") }}</h5>
              <p>
                <span class="font-bold">{{ $t("primary_input.answer_mic") }}</span> {{ $t("primary_input.answer_with_mic") }}
              </p>
              <h5 class="font-bold mb-1 mt-2">{{ $t("alarm_action.action_in_alarm") }}</h5>
              <ol>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.co_listening") }}</span> {{ $t("alarm_action.confirm_alarm_type") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.summon") }}</span> {{ $t("alarm_action.police_and_external_agent") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.emergancy_event") }}</span
                  >{{ $t("alarm_action.communicate") }}
                </li>
                <li>{{ $t("alarm_action.options") }}</li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.contact_person") }}</span> {{ $t("alarm_action.notify_sharp_alarm") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.recall") }}</span
                  >{{ $t("alarm_action.alarm_authorization") }}
                </li>
              </ol>
            </div>

            <div class="w-full px-2 text-xs italic" v-if="updateInfo.risk_profile == 'Nödlarm'">
              <h5 class="font-bold mb-1">{{ $t("primary_input_mic_on.primary_input") }}</h5>
              <p>
                <span class="font-bold">{{ $t("primary_input_mic_on.primary_input") }}</span> {{ $t("primary_input_mic_on.answer_with_mic") }}
              </p>
              <h5 class="font-bold mb-1 mt-2">{{ $t("alarm_action.action_in_alarm") }}</h5>
              <ol>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.co_listening") }}</span> {{ $t("alarm_action.confirm_alarm_type") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("communicate.communicate") }}</span> {{ $t("communicate.talk_to_persion") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.contact_person") }}</span
                  >{{ $t("alarm_action.notify_sharp_alarm2") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.recall") }} </span>{{ $t("alarm_action.alarm_authorization") }}
                </li>
              </ol>
            </div>

            <div class="w-full px-2 text-xs italic" v-if="updateInfo.risk_profile == 'Överfallslarm (ej återkallningsbart)'">
              <h5 class="font-bold mb-1">{{ $t("primary_input.primary_input") }}</h5>
              <p>
                <span class="font-bold">{{ $t("primary_input.answer_mic") }}</span
                >{{ $t("risk_profile.burglar_alarm") }}
              </p>
              <h5 class="font-bold mb-1 mt-2">{{ $t("alarm_action.action_in_alarm") }}</h5>
              <ol>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.summon") }}</span> {{ $t("info.info_1") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.co_listening") }}</span> {{ $t("info.info_2") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.contact_person") }}</span> {{ $t("info.info_3") }}
                </li>
                <li>
                  <span class="font-bold">{{ $t("alarm_action.recall") }}:</span> {{ $t("info.info_4") }}
                </li>
              </ol>
            </div>
          </div>
        </ValidationObserver>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <button v-if="user.role == 'superuser' || user.role == 'user' || user.role == 'reseller'" class="btn-red-outline px-3 py-2 float-left" @click="hide('update-alarm-contract')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>

          <button v-if="updateInfo && updateInfo.alarm_central != '-' && (user.role == 'superuser' || user.role == 'user' || user.role == 'reseller')" class="btn-green px-3 py-2 float-right" @click="saveAndSendPDF()">{{ $t("change_alarm_plan.send_to_alarm_center") }}<BaseIcon icon="save" class="ml-1" /></button>
          <button v-if="user.role == 'alarm_center'" class="btn-green px-3 py-2 float-left" @click="updateAlarmContract()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
          <button v-if="user.role == 'alarm_center'" class="btn-blue-outline px-3 py-2 float-right" @click="downloadPDF()">{{ $t("user_actions.download") }}<BaseIcon icon="file-pdf" class="ml-1" /></button>
          <button v-if="updateInfo && updateInfo.alarm_central != '-' && (user.role == 'admin' || user.role == 'seller')" class="btn-blue-outline px-3 py-2 float-left" @click="saveAndSendPDF()">{{ $t("change_alarm_plan.send_to_alarm_center") }}<BaseIcon icon="envelope" class="ml-1" /></button>
          <button v-if="user.role == 'admin' || user.role == 'seller'" class="btn-green px-3 py-2 float-right" @click="updateAlarmContract()">{{ $t("user_actions.save") }}<BaseIcon icon="save" class="ml-1" /></button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
const MODAL_WIDTH = 1200;
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  props: {
    alarmInfo: {
      required: true,
      default: null,
    },
    alarmCentrals: {
      required: true,
    },
    sellers: {
      required: true,
    },
    customer: {
      required: true,
      default: null,
    },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      updateInfo: null,
      devices: ["SRT306", "SRT306i", "SRT306 G2", "SRT326", "SRT330", "SRT334", "SRT405", "SRT405i", "SRT406", "SRT406i", "SRT430", "SRT278", "SRT341", "TWIG Neo", "TWIG Neo SRD", "TWIG Neo MD", "TWIG Neo SRD MD", "TWIG One", "TWIG One SRD", "TWIG One EX", "TWIG Beacon", "TWIG Beacon EX", "EMERIT WG100", "TELTONIKA FMC003", "4G Box"],
      sim_operators: ["Telia", "Telia DCP", "Telenor", "Tele2", "Kundens Simkort"],
      show_alarm_number: false,
      show_personal_alarm: false,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  watch: {
    alarmInfo(newVal) {
      // eslint-disable-line
      this.updateInfo = _.cloneDeep(newVal);
      this.check_personal_alarm();
      this.check_alarm_number();
    },
    alarmCentrals() {
      // eslint-disable-line
      this.check_alarm_number();
    },
    "$i18n.locale"(newLocale) {
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },

  methods: {
    updateAlarmContract() {
      this.$refs.formUpdateAlarmContract.validate().then((success) => {
        if (!success) {
          this.handleError(null, this.$t("change_alarm_plan.fields_are_empty"));
          return;
        }

        let data = {
          type: this.updateInfo.type,
          imei_number: this.updateInfo.imei_number,
          phone: this.updateInfo.phone,
          modelnumber: this.updateInfo.modelnumber,
          operator: this.updateInfo.operator,
          alarm_central: this.updateInfo.alarm_central,
          alarm_number: this.updateInfo.alarm_number,
          man_down: this.updateInfo.man_down,
          agreement_number: this.updateInfo.agreement_number,
          agreement_length: this.updateInfo.agreement_length,
          seller: this.updateInfo.seller,
          delivery_company: this.updateInfo.delivery_company,
          selected_alarm_sort: this.updateInfo.selected_alarm_sort,
          personal_alarm: this.updateInfo.personal_alarm,
          risk_profile: this.updateInfo.risk_profile,
          user: this.updateInfo.user,
          unit_name: this.updateInfo.unit_name,
          from_address: this.updateInfo.from_address,
          alarm_unit_name: this.updateInfo.alarm_unit_name,
          business_description: this.updateInfo.business_description,
          alarm_password: this.updateInfo.alarm_password,
          personal_password: this.updateInfo.personal_password,
          external_contractor: this.updateInfo.external_contractor,
          contact_persons: this.updateInfo.contact_persons,
          comment: this.updateInfo.comment,
          contract_start: this.updateInfo.contract_start,
        };

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/alarms/${this.updateInfo._id}`, data)
          .then(() => {
            //eslint-disable-line
            this.$emit("updateAlarmContract");
            this.$modal.hide("update-alarm-contract");
            this.$modal.hide("update-alarm");
          })
          .catch((error) => {
            this.isLoading = false;
            this.handleError(error);
          });
      });
    },

    saveAndSendPDF(alarm) {
      this.$refs.formUpdateAlarmContract.validate().then(async (success) => {
        if (!success) {
          this.handleError(null, this.$t("change_alarm_plan.fields_are_empty"));
          return;
        }

        this.isLoading = true;
        await this.updateAlarmContract();
        this.isLoading = false;
        await this.sendPDF(alarm);
      });
    },

    async sendPDF() {
      let alarm = this.updateInfo;

      // send only for alarms
      if (["SRT330", "SRT430", "SRT334", "TWIG Beacon"].indexOf(alarm.type) > -1) return;

      this.isLoading = true;

      let customerName = "";
      if (this.customer) customerName = this.customer.name;

      let doc = await this.generateAlarmPlanPDF({ alarm: alarm, customerName: customerName, sellers: this.sellers, oldAlarm: this.alarmInfo, highlight: true, showAlarmNumber: this.show_alarm_number });
      var base = doc.output("blob");

      let headers = {
        "X-Access-Token": this.$store.state.token,
        "Content-Type": "multipart/form-data",
      };

      var formData = new FormData();
      var pdfFile = base;
      formData.append("pdf", pdfFile);

      formData.append("alarm_id", alarm._id);

      this.axios
        .post(`${process.env.VUE_APP_SERVER_URL}/alarm-center/${alarm.alarm_central}/email`, formData, headers)
        .then((response) => {
          if (response.data.error) this.handleError(null, this.$t("change_alarm_plan.email_send_error"));
          if (response.data.success) this.handleSuccess(this.$t("change_alarm_plan.sent_to_alarm_center"));
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error, this.$t("change_alarm_plan.email_send_error"));
        });
    },

    async downloadPDF() {
      try {
        // download only for alarms
        if (["SRT330", "SRT430", "SRT334", "TWIG Beacon"].indexOf(this.updateInfo.type) > -1) return;

        let customerName = "";
        if (this.customer) customerName = this.customer.name;

        let doc = await this.generateAlarmPlanPDF({ alarm: this.updateInfo, customerName: customerName, sellers: this.sellers, oldAlarm: this.alarmInfo, highlight: false, showAlarmNumber: this.show_alarm_number });
        doc.save(`${this.updateInfo.alarm_unit_name}-${this.updateInfo.unit_name}-${this.updateInfo.phone}.pdf`);
      } catch (error) {
        this.handleError(error);
      }
    },

    check_alarm_number() {
      if (this.updateInfo) {
        let a = _.find(this.alarmCentrals, { _id: this.updateInfo.alarm_central });

        if (a && /(Stanley Security)|(Securitas AB)|(Rapid Larmcentral)|(Cubsec Larmcentral)/.test(a.name)) this.show_alarm_number = true;
        else this.show_alarm_number = false;
      }
    },

    check_personal_alarm() {
      if (this.updateInfo && this.updateInfo.selected_alarm_sort == "Personligt") this.show_personal_alarm = true;
      else this.show_personal_alarm = false;
    },

    switchAgreementType() {
      if (this.updateInfo.cash_agreement) {
        this.updateInfo.agreement_number = "Kontant";
        this.updateInfo.agreement_length = "-";
      } else if (!this.updateInfo.cash_agreement && new RegExp("^SE").test(this.alarmInfo.agreement_number)) {
        this.updateInfo.agreement_number = this.alarmInfo.agreement_number;
        this.updateInfo.agreement_length = this.alarmInfo.agreement_length;
      } else {
        this.updateInfo.agreement_number = "";
        this.updateInfo.agreement_length = "";
      }
    },

    hide(id) {
      this.$refs.formUpdateAlarmContract.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
